export interface IUser {
  _id?: string
  name?: string
  password?: string
  generatedPassword?: string
  email?: string
  phone?: string
  activated?: boolean
  lastLogin?: Date
  lastActivity?: Date
  createdAt?: Date
  updatedAt?: Date
}

export class User implements IUser {
  constructor (
    public _id?: string,
    public username?: string,
    public password?: string,
    public generatedPassword?: string,
    public email?: string,
    public phone?: string,
    public activated?: boolean,
    public lastLogin?: Date,
    public lastActivity?: Date,
    public createdAt?: Date,
    public updatedAt?: Date,
    public uuid?: string
  ) {
    this._id = _id
    // return this
  }
}
