import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import userService from '@/shared/services/userService'
import store from '@/store'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: () => import('@/views/PagesView.vue'),
    children: [
      {
        name: 'Homepage',
        path: '',
        component: () => import('@/views/pages/HomePage.vue')
      },
      {
        name: 'Abmelden',
        path: 'participant/:uuid',
        component: () => import('@/views/pages/DeRegisterParticipant.vue')
      },
      {
        name: 'Login',
        path: 'login',
        component: () => import('@/views/pages/Login.vue')
      },
      {
        name: 'Confirm password reset',
        path: 'confirm-password-reset/:resetId',
        component: () => import('@/views/pages/ConfirmPasswordReset.vue')
      }
    ]
  },
  {
    path: '/app',
    component: () => import('@/views/AppView.vue'),
    children: [
      {
        name: 'Startseite',
        path: '',
        component: () => import('@/views/app/Dashboard.vue')
      },
      {
        name: 'UserProfile',
        path: 'profile',
        component: () => import('@/views/app/UserProfile.vue')
      },
      {
        name: 'Passwort ändern',
        path: 'change-password',
        component: () => import('@/views/app/ChangePassword.vue')
      },
      {
        name: 'Wizards',
        path: 'wizards',
        component: () => import('@/views/app/Wizard.vue')
      },
      {
        name: 'Benutzer',
        path: 'users',
        component: () => import('@/views/app/UsersTable.vue')
      },
      {
        name: 'Applikationseinstellungen',
        path: 'settings/app',
        component: () => import('@/views/app/AppSettings.vue')
      },
      {
        name: 'Angebotsinhalte',
        path: 'lectureContent',
        component: () => import('@/views/app/LectureContent.vue')
      },
      {
        name: 'Passwort zurücksetzen',
        path: 'reset-password',
        component: () => import('@/views/app/ResetPassword.vue')
      },
      {
        name: 'Termine',
        path: 'lecture',
        component: () => import('@/views/app/Lecture.vue')
      },
      {
        path: '*',
        component: () => import('@/views/shared/ErrorPage.vue')
      }
    ]
  },
  {
    path: '*',
    component: () => import('@/views/PagesView.vue'),
    children: [
      {
        name: 'Fehlermeldung',
        path: '',
        component: () => import('@/views/shared/ErrorPage.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (!to.matched.length) {
    next('/404')
  }

  await userService.getMyself()

  if (store.getters.authenticated) {
    if (to.path.includes('/login')) {
      router.push('/app')
    }
  }
  next()
})

export default router
