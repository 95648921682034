import Vue from 'vue'
import Vuex from 'vuex'
import userStore from './modules/user'
import lectureContentStore from './modules/lectureContent'
import lectureStore from './modules/lecture'

Vue.use(Vuex)

const appStore = {
  state: {
    authLoading: true,
    account: null,
    authenticated: false,
    dark: false,
    drawer: null,
    mini: false
  },
  getters: {
    authLoading: (state: any) => state.authLoading,
    account: (state: any) => state.account,
    authenticated: (state: any) => state.authenticated,
    dark: (state: any) => state.dark,
    drawer: (state: any) => state.drawer,
    mini: (state: any) => state.mini
  },
  mutations: {
    authenticate (state: any) {
      state.authLoading = true
    },
    authenticated (state: any, account: any) {
      state.account = account
      state.authenticated = true
      state.authLoading = false
    },
    logout (state: any) {
      state.account = null
      state.authenticated = false
      state.authLoading = false
    },
    setDrawer (state: any, payload: any) {
      state.drawer = payload
    },
    setMini (state: any, payload: boolean) {
      state.mini = payload
    },
    setDark (state: any, payload: boolean) {
      state.dark = payload
    },
    resetMain (state: any) {
      state.authLoading = false
      state.account = null
      state.authenticated = false
      state.drawer = null
      state.mini = false
    }
  }
}

const store = new Vuex.Store({
  modules: {
    appStore: appStore,
    userStore: userStore,
    lectureContentStore: lectureContentStore,
    lectureStore: lectureStore
  }
})

export default store
